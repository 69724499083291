import { lazy } from "react";
import { Navigate } from "react-router-dom";

const Header = lazy(() => import("../../components/Header"));
const Footer = lazy(() => import("../../components/Footer"));
const Login = lazy(() => import("../../screen/Login"));
// const NotFound = lazy(() => import('../../screen/NotFound'));
const Campaign = lazy(
  () => import(/* webpackPrefetch: true */ "../../screen/Campaign")
);
const CampaignCreate = lazy(
  () => import(/* webpackPrefetch: true */ "../../screen/CampaingCreate")
);
const Management = lazy(
  () => import(/* webpackPrefetch: true */ "../../screen/Management")
);
const BlackList = lazy(
  () => import(/* webpackPrefetch: true */ "../../screen/BlackList")
);
const UserInterface = lazy(
  () => import(/* webpackPrefetch: true */ "../../screen/UserInterface")
);
const CustomerResponse = lazy(
  () => import(/* webpackPrefetch: true */ "../../screen/CustomerResponse")
);

export const ROUTE_PATHS = {
  DEFAULT: "/",
  LOGIN: "/login",
  NOT_FOUND: "404",
  CAMPAIGN: "/campaign",
  CAMPAIGN_CREATE: "/campaign-create",
  MANAGEMENT: "/management",
  BLACK_LIST: "/black-list",
  USER_INTERFACE: "/user-interface",
  CUSTOMER_RESPONSE: "/customer-response",
};

const routes = [
  {
    path: ROUTE_PATHS.DEFAULT,
    element: (
      <>
        <Login />
      </>
    ),
    children: [],
  },
  // {
  //   path: ROUTE_PATHS.NOT_FOUND,
  //   element: (
  //     <>
  //       <NotFound />
  //     </>
  //   ),
  // },
  { path: "*", element: <Navigate to={ROUTE_PATHS.NOT_FOUND} /> },
  {
    path: ROUTE_PATHS.CAMPAIGN,
    element: (
      <>
        <Header />
        <Campaign />
        <Footer />
      </>
    ),
    children: [],
  },
  {
    path: ROUTE_PATHS.CAMPAIGN_CREATE,
    element: (
      <>
        <Header />
        <CampaignCreate />
        <Footer />
      </>
    ),
    children: [],
  },
  {
    path: ROUTE_PATHS.MANAGEMENT,
    element: (
      <>
        <Header />
        <Management />
        <Footer />
      </>
    ),
    children: [],
  },
  {
    path: ROUTE_PATHS.BLACK_LIST,
    element: (
      <>
        <Header />
        <BlackList />
        <Footer />
      </>
    ),
    children: [],
  },
  {
    path: ROUTE_PATHS.USER_INTERFACE,
    element: (
      <>
        <Header />
        <UserInterface />
        <Footer />
      </>
    ),
    children: [],
  },
  {
    path: ROUTE_PATHS.CUSTOMER_RESPONSE,
    element: (
      <>
        <Header />
        <CustomerResponse />
        <Footer />
      </>
    ),
    children: [],
  },
];

export default routes;
