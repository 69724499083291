import styled from "styled-components";

export const AppDiv = styled.div`
  @import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");

  font-family: "Roboto", sans-serif;
`;

export const Button = styled.button`
  background-color: #e7762a;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  border: 2px solid #e7762a;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.2s;

  :hover {
    opacity: 0.85;
  }
`;
